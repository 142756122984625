<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <form>
        <div class="columns">
          <div class="column">
            <b-field>
              <ValidationProvider
                rules="required|max:300"
                v-slot="{ errors, valid }"
              >
                <b-field
                  :label="t('forms.name')"
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0], { max: 300 })"
                >
                  <b-input
                    v-model="deepZoom.title"
                    maxlength="300"
                    :has-counter="false"
                    required
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>

            <b-field>
              <ValidationProvider
                rules="max:300"
                v-slot="{ errors, valid }"
              >
                <b-field
                  :label="t('forms.nameInEnglish')"
                  label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0], { max: 300 })"
                >
                  <b-input
                    v-model="deepZoom.titleEn"
                    maxlength="300"
                    :has-counter="false"
                    required
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>

            <b-field>
              <ValidationProvider
                rules="max:300"
                v-slot="{ errors, valid }"
              >
              <b-field :label="t('forms.description')" label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0], { max: 300 })">
                <b-input
                  v-model="deepZoom.description"
                  maxlength="300"
                  :has-counter="false"
                ></b-input>
              </b-field>
              </ValidationProvider>
            </b-field>

            <b-field>
              <ValidationProvider
                rules="max:300"
                v-slot="{ errors, valid }"
              >
              <b-field :label="t('forms.descriptionInEnglish')" label-position="on-border"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="t(errors[0], { max: 300 })">
                <b-input
                  v-model="deepZoom.descriptionEn"
                  maxlength="300"
                  :has-counter="false"
                ></b-input>
              </b-field>
              </ValidationProvider>
            </b-field>

            <b-field>
              <b-field :label="t('forms.category')" label-position="on-border">
                <b-select v-model="deepZoom.category" expanded>
                  <option value="">{{t('common.noData')}}</option>
                  <option
                    v-for="(item, index) in afmConfig.deepZoomCategories"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </b-select>
              </b-field>
            </b-field>

            <b-field>
              <b-field :label="t('deepzoom.source')" label-position="on-border">
                <b-select v-model="deepZoom.source" expanded>
                  <option value="">{{t('common.noData')}}</option>
                  <option
                    v-for="(item, index) in afmConfig.deepZoomSources"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </b-select>
              </b-field>
            </b-field>

            <b-field :label="t('forms.tags')" label-position="on-border">
              <b-taginput
                v-model="deepZoom.tags"
                ellipsis
                icon="plus"
                :placeholder="t('common.add')"
                maxlength="300"
                type="is-primary"
                :aria-close-label="t('common.remove')"
              ></b-taginput>
            </b-field>

            <b-button
              @click="saveConfig"
              :loading="isLoading"
              icon-left="account-check"
              expanded
              type="is-primary"
              :disabled="invalid"
            >
              <span>{{t('common.save')}}</span>
            </b-button>
          </div>
          <div class="column">
            <div
              class="columns is-2"
              v-for="layer in deepZoom.layers"
              :key="layer.id"
            >
              <div class="column ">
                <p class="subtitle is-6 pb-2 pt-0 mb-0 mt-0">{{t('deepzoom.numberLayer')}} {{ layer.id }}</p>
                <div class="columns mb-0">
                  <div class="column">
                    <ValidationProvider
                      rules="required|max:300"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        :label="t('deepzoom.titleLayer')"
                        label-position="on-border"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="t(errors[0], { max: 300 })"
                      >
                        <b-input
                          v-model="layer.title"
                          maxlength="300"
                          :has-counter="false"
                          required
                          expanded
                        ></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column is-half">
                    <ValidationProvider
                      rules="max:300"
                      v-slot="{ errors, valid }"
                    >
                      <b-field
                        :label="t('deepzoom.titleLayerEn')"
                        label-position="on-border"
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="t(errors[0], { max: 300 })"
                      >
                        <b-input
                          v-model="layer.titleEn"
                          maxlength="300"
                          :has-counter="false"
                          required
                          expanded
                        ></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>
                </div>
                <b-field>
                  <ValidationProvider
                      rules="max:300"
                      v-slot="{ errors, valid }"
                    >
                      <b-field :label="t('deepzoom.descLayer')" label-position="on-border"
                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="t(errors[0], { max: 300 })">
                          <b-input
                            v-model="layer.description"
                            maxlength="300"
                            :has-counter="false"
                            expanded
                          ></b-input>
                      </b-field>
                  </ValidationProvider>
                </b-field>
                <b-field>
                  <ValidationProvider
                      rules="max:300"
                      v-slot="{ errors, valid }"
                    >
                      <b-field :label="t('deepzoom.descLayerEn')" label-position="on-border"
                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                            :message="t(errors[0], { max: 300 })">
                          <b-input
                            v-model="layer.descriptionEn"
                            maxlength="300"
                            :has-counter="false"
                            expanded
                          ></b-input>
                      </b-field>
                  </ValidationProvider>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Action } from "../../store/config_actions";

export default {
  name: "DeepZoomEdit",
  mixins: [CommonHelpers],
  components: { ValidationProvider, ValidationObserver },

  props: {
    deepZoomParent: { type: Object, required: true },
  },

  data: function () {
    return {
      afmConfig: {},
      isLoading: false,
      deepZoom:{}
    };
  },

  mounted() {
    this.getAfmConfig();
    this.deepZoom = JSON.parse(JSON.stringify(this.deepZoomParent))
  },

  watch: {},

  computed: {},

  methods: {
    saveConfig() {
      let object={
        org: this.deepZoomParent,
        change: JSON.parse(JSON.stringify(this.deepZoom))
      }
        this.$emit("save",object);
    },
    /**
     * Get AFM config
     */
   getAfmConfig() {
      this.isLoading = true;
      if(this.$store.state.afmconfig.config){
        this.afmConfig = this.$store.state.afmconfig.config;
        this.isLoading = false;
      }else{
      this.$store
        .dispatch(Action.AFM_CONFIG_GET)
        .then((payload) => {
          this.afmConfig = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
