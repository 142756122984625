<template>
  <div class="home">
      <DeepZoom/>
  </div>
</template>

<script>

import DeepZoom from '@/components/deepzoom/DeepZoom'

export default {
  name: 'DeepZoomView',
  components: {
      DeepZoom
  }
}
</script>