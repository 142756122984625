<template>
  <div class="card mt-4 ml-5 mr-5" style="min-height: 70vh;">
    <b-tabs :animated="false">
      <b-tab-item :label="t('componentNames.deepZoom')" v-model="tab" icon="image-album">
        <DeepZoomList></DeepZoomList>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import Commons from "@/mixins/commons";
import DeepZoomList from "./DeepZoomList"


export default {
  name: "DeepZoom",
  mixins: [Commons],
  components: {
    DeepZoomList
  },

  data: function () {
    return {
      tab: "0"
    };
  },

  mounted() {
  },

  methods: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
