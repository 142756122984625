<template>
  <div>
    <UserFeedback :target="'DeepZoom'" class="end mr-4 mt-1"></UserFeedback>
    <p class="mb-1">
      <b-field grouped group-multiline>
        <b-field class="mr-2">
          <b-button
            :loading="isLoading"
            @click="getDeepZoomConfig"
            icon-left="refresh"
            size="is-small"
            >{{ t("deepzoom.refreshList") }}</b-button
          >
        </b-field>
        <b-field
          :label="t('deepzoom.source')"
          label-position="on-border"
          style="min-width: 20rem"
          class="wide"
        >
          <b-select v-model="sourceFilter" size="is-small" expanded>
            <option :value="null">{{ t("common.all") }}</option>
            <option
              v-for="(item, index) in sources2"
              :key="index"
              :value="item"
            >
              {{ item }}
            </option>
          </b-select>
        </b-field>
      </b-field>
    </p>
    <b-table
      :data="deepZoomFiltered"
      striped
      hoverable
      :detailed="isDetailed"
      detail-key="id"
      :per-page="perPage"
      paginated
      :current-page.sync="currentPage"
      :row-class="(row, index) => 'row-one-height'"
      :default-sort="['internalId', 'asc']"
    >
      <b-table-column
        :label="t('common.lp')"
        width="1rem"
        centered
        v-slot="props"      
        >
        <!-- {{
          deepzoomTranslated.images != null
            ? deepzoomTranslated.images.indexOf(props.row) + 1
            : props.index + 1
        }} :custom-sort="sortByLp"-->
        <!-- {{
          deepZoomFiltered != null
            ? deepZoomFiltered.indexOf(props.row) + 1
            : props.index + 1
        }} -->
        <!-- {{props.index + 1}} -->
        {{ (perPage * (currentPage-1)) + props.index + 1}}
        </b-table-column
      >
      <b-table-column v-slot="props" width="1rem">
        <b-button
          icon-left="magnify"
          size="is-small"
          :type="{
            'is-primary': selectedId != null && selectedId == props.row.id,
          }"
          @click="showDeepZoom(props.row.id)"
          >{{ t("common.preview") }}</b-button
        >
      </b-table-column>
      <b-table-column
        :label="t('common.internalId')"
        field="internalId"
        v-slot="props"
        sortable 
        width="6.5rem"       
      >
        <!-- {{ getInternalId(props.row.source) }}  :custom-sort="sortByInternal"-->
        {{props.row.internalId}}
      </b-table-column>
      <b-table-column width="2rem" field="id" searchable sortable :label="t('deepzoom.id')">
        <template #searchable>
          <b-input expanded v-model="filters.id" />
        </template>
        <template v-slot="props">
          {{ props.row.id }}
        </template>
      </b-table-column>
      <b-table-column field="title" searchable sortable :label="t('forms.name')"
        ><template #searchable>
          <b-input expanded v-model="filters.name" />
        </template>
        <template v-slot="props">
          <p>{{ props.row.title }}</p>
          <p v-if="props.row.titleEn != null && props.row.titleEn.length ">{{ props.row.titleEn }}</p>
        </template>
      </b-table-column>
      <b-table-column
        field="category"
        searchable
        sortable
        :label="t('forms.category')"
      >
        <template #searchable>
          <b-input expanded v-model="filters.category" />
        </template>
        <template v-slot="props">
          {{ props.row.category }}</template
        ></b-table-column
      >
      <b-table-column
        field="source"
        searchable
        sortable
        :label="t('forms.source')"
        width="2rem"
        ><template #searchable>
          <b-input expanded v-model="filters.source" />
        </template>
        <template v-slot="props">{{
          props.row.source
        }}</template></b-table-column
      >
      <b-table-column
        field="description"
        searchable
        sortable
        :label="t('forms.description')"
        ><template #searchable
          ><b-input expanded v-model="filters.description" />
        </template>
        <template v-slot="props">
          <p>{{
          props.row.description
        }}</p>
        <p v-if="props.row.descriptionEn != null && props.row.descriptionEn.length ">{{ props.row.descriptionEn }}</p>
        </template></b-table-column
      >
      <b-table-column
        field="tags"
        searchable
        sortable
        :label="t('forms.tags')"
        v-slot="props"
      >
        <b-taglist>
          <b-tag v-for="tag in props.row.tags" :key="tag" size="is-small">{{
            tag
          }}</b-tag>
        </b-taglist>
      </b-table-column>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="package-variant" size="is-large"></b-icon>
            </p>
            <p>{{ t("common.noData") }}</p>
          </div>
        </section>
      </template>
      <template slot="detail" slot-scope="props">
        <DeepZoomEdit :deepZoomParent="props.row" @save="updateConfig"></DeepZoomEdit>
      </template>
    </b-table>
  </div>
</template>

<script>
import { Action } from "../../store/config_actions";
import Commons from "@/mixins/commons";
import DeepZoomEdit from "./DeepZoomEdit";
import UserFeedback from "@/components/feedbacks/UserFeedback";
import { Mutation } from "../../store/config_mutations";

export default {
  name: "DeepZoomList",
  mixins: [Commons],
  components: {
    DeepZoomEdit,
    UserFeedback,
  },

  props: {},

  watch: {},

  data: function () {
    return {
      isLoading: false,
      deepZoomConfig: {},
      sourceFilter: null,
      sources: [],
      currentPage: 1,
      perPage: 12,
      selectedId: null,
      filters: {
        id: "",
        name: "",
        category: "",
        source: "",
        description: "",
        sourceFilter: null,
      },
      //isDetailed:false
    };
  },

  computed: {
    session() {
      return this.$store.state.auth.session;
    },

    isAdmin() {
      if (this.session && this.session.roles.includes("Admin")) return true;
      else return false;
    },

    isTeacher() {
      if (this.session && this.session.roles.includes("Teacher")) return true;
      else return false;
    },

    isDetailed() {
      if (this.isAdmin || this.isTeacher) return true;
      else return false;
    },

    sources2() {
      let tempSource = [];

      if (this.deepzoomTranslated != null && this.deepzoomTranslated.images != null) {
        this.deepzoomTranslated.images.forEach((image) => {
          if (
            image.source != null &&
            image.source != "" &&
            !tempSource.includes(image.source)
          ) {
            tempSource.push(image.source);
          }
        });
      }

      return tempSource;
    },

    isBack() {
      return this.$store.state.deepzoom.isBack ?? false;
    },

    lastPage() {
      return this.$store.state.deepzoom.lastPage ?? -1;
    },

    lastSelectedDeepzoom() {
      return this.$store.state.deepzoom.lastSelectedDeepzoom;
    },

    lastFilter() {
      return this.$store.state.deepzoom.lastFilters;
    },

    lastScroll() {
      return this.$store.state.deepzoom.lastScroll;
    },

    afmConfig() {
      return this.$store.state.afmconfig.config;
    },

    // deepzoomSorted(){
    //   let result = this.deepZoomConfig
    //   if(result.images != null){
    //     result.images = result.images.sort(this.sortByDigitmed).reverse()
    //   }

    //   return result;
    // },

    deepzoomTranslated(){
      let result = this.deepZoomConfig

      if(result.images != null){
        
        result.images.forEach(image => {
          if(image != null){
            let internal = this.getInternalId(image.source)
            if(internal != null){
              image.internalId = internal +"-"+image.code
            }else{
              image.internalId = "99-"+image.code
            }
          }
          
        });
      }

      return result
    },

    deepZoomFiltered() {
      let filtered = [];

      if (this.deepzoomTranslated != null && this.deepzoomTranslated.images != null) {
        filtered = this.deepzoomTranslated.images;

        if (this.sourceFilter != null && this.sourceFilter != "") {
          filtered = filtered.filter((image) => {
            if (
              image.source != null &&
              image.source.toLowerCase() == this.sourceFilter.toLowerCase()
            ) {
              return true;
            } else return false;
          });
        }
        //

        filtered = filtered.filter((option) => {
          if (option.title != null || option.titleEn != null) {
            return (
              (option.title != null &&
              option.title
                .toLowerCase()
                .indexOf(this.filters.name.toLowerCase()) >= 0) ||(option.titleEn != null &&
              option.titleEn
                .toLowerCase()
                .indexOf(this.filters.name.toLowerCase()) >= 0)
            );
          }else if (this.filters.name != null && this.filters.name.length > 0)
            return false;
          else return true;
        });

        // filtered = filtered.filter((option) => {
        //   if (option.titleEn != null) {
        //     return (
        //       option.titleEn != null &&
        //       option.titleEn
        //         .toLowerCase()
        //         .indexOf(this.filters.name.toLowerCase()) >= 0
        //     );
        //   } else if (this.filters.name != null && this.filters.name.length > 0)
        //     return false;
        //   else return true;
        // });

        filtered = filtered.filter((option) => {
          if (option.id != null) {
            return (
              option.id != null &&
              option.id.toLowerCase().indexOf(this.filters.id.toLowerCase()) >=
                0
            );
          } else if (this.filters.id != null && this.filters.id.length > 0)
            return false;
          else return true;
        });

        filtered = filtered.filter((option) => {
          if (option.category != null) {
            return (
              option.category
                .toLowerCase()
                .indexOf(this.filters.category.toLowerCase()) >= 0
            );
          } else if (
            this.filters.category != null &&
            this.filters.category.length > 0
          )
            return false;
          else return true;
        });

        filtered = filtered.filter((option) => {
          if (option.source != null) {
            return (
              option.source
                .toLowerCase()
                .indexOf(this.filters.source.toLowerCase()) >= 0
            );
          } else if (
            this.filters.source != null &&
            this.filters.source.length > 0
          )
            return false;
          else return true;
        });

        filtered = filtered.filter((option) => {
          if (option.description != null || option.descriptionEn != null) {
            return (
              (option.description != null && option.description
                .toLowerCase()
                .indexOf(this.filters.description.toLowerCase()) >= 0) ||(option.descriptionEn != null &&
              option.descriptionEn
                .toLowerCase()
                .indexOf(this.filters.description.toLowerCase()) >= 0)
            );
          } else if (
            this.filters.description != null &&
            this.filters.description.length > 0
          )
            return false;
          else return true;
        });
      }

      return filtered;
    },
  },

  mounted() {
    if (this.afmConfig == null) {
      this.getAfmConfig();
    }

    if (this.$store.state.deepzoom.config) {
      this.deepZoomConfig = this.$store.state.deepzoom.config;
    } else {
      this.getDeepZoomConfig();
    }

    if (this.isBack) {
      this.currentPage = JSON.parse(JSON.stringify(this.lastPage));
      if (this.lastFilter != null) {
        this.sourceFilter = JSON.parse(
          JSON.stringify(this.lastFilter.sourceFilter)
        );
        this.filters = JSON.parse(JSON.stringify(this.lastFilter));
      }

      if (this.lastScroll != null && this.lastScroll > 0) {
        setTimeout(this.myScrollTo, 100);
      }
      this.selectedId = JSON.parse(JSON.stringify(this.lastSelectedDeepzoom));

      this.$store.commit(Mutation.DEEPZOOM_FILTER_RESET_ALL);
    } else {
      this.selectedId = null;
      this.currentPage = 1;
      //this.filters = this.resetFilters;
      this.sourceFilter = null;
    }
  },

  methods: {
    /** */
    getDeepZoomConfig() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.DEEPZOOM_CONFIG_GET)
        .then((payload) => {
          this.sources.length = 0;
          this.currentPage = 1;
          this.selectedId = null;
          //this.filters = this.resetFilters
          if (payload != null && payload.images != null) {
            payload.images.forEach((image) => {
              image.category = null;
              if (
                image.source != null &&
                image.source != "" &&
                !this.sources.includes(image.source)
              ) {
                this.sources.push(image.source);
              }
            });
          }
          this.deepZoomConfig = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    resetFilters() {
      return {
        id: "",
        name: "",
        category: "",
        source: "",
        description: "",
        sourceFilter: null,
      };
    },

    myScrollTo() {
      window.scrollTo(0, this.lastScroll);
      this.$store.commit(Mutation.DEEPZOOM_RESET_SCROLL);
    },

    showDeepZoom(id) {
      this.$store.commit(
        Mutation.DEEPZOOM_SET_SCROLL,
        JSON.parse(JSON.stringify(window.scrollY))
      );
      this.filters.sourceFilter = JSON.parse(JSON.stringify(this.sourceFilter));
      this.$store.commit(
        Mutation.DEEPZOOM_SET_FILTERS,
        JSON.parse(JSON.stringify(this.filters))
      );
      this.$store.commit(Mutation.DEEPZOOM_SET_LAST_SELECTED, id);
      this.$store.commit(Mutation.DEEPZOOM_SET_LAST_PAGE, this.currentPage);
      this.$router.push({ name: "opensea", params: { imageId: id } });
    },

    updateConfig(objectToSave) {
      let index = this.deepZoomConfig.images.indexOf(objectToSave.org)
      if(index>-1) this.deepZoomConfig.images[index] = objectToSave.change
      else this.mDangerSnack('Błąd aktualizacji')
      let result = JSON.parse(JSON.stringify(this.deepZoomConfig)) 

      if(result.images != null){
        result.images.forEach(image => {
          if(image != null){
            delete image.internalId 
          }
        });
      }
      this.isLoading = true;
      this.$store
        .dispatch(Action.DEEPZOOM_CONFIG_CREATE, result)
        .then((response) => {
          this.deepZoomConfig = response;
          this.mSuccessSnack(this.t("common.changesSaved"));
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    sortByDigitmed(first, second) {
      let firstFound =
        first.source != null
          ? first.source.toLowerCase().indexOf("digitmed")
          : -1;
      let secondFound =
        second.source != null
          ? second.source.toLowerCase().indexOf("digitmed")
          : -1;

      if (firstFound > -1 || secondFound > -1) {
        if (secondFound > firstFound) {
          return secondFound - firstFound;
        } else {
          return firstFound - secondFound;
        }
      } else {
        return -1;
      }
    },

    sortByLp(first, second, isAsc) {
      if (this.deepzoomTranslated != null && this.deepzoomTranslated.images != null) {
        let indexFirst = this.deepzoomTranslated.images.indexOf(first);
        let indexSecond = this.deepzoomTranslated.images.indexOf(second);
        if (isAsc) {
          return indexSecond - indexFirst;
        } else {
          return indexFirst - indexSecond;
        }
      } else {
        return 0;
      }
    },

    // sortByInternal(first, second, isAsc) {
    //   let firstId = this.getInternalId(first.source);
    //   let secondId = this.getInternalId(second.source);
    //   if (firstId === null) return 1;
    //   if (secondId === null) return -1;
    //   if (firstId < secondId) return isAsc ? -1 : 1;
    //   if (firstId > secondId) return isAsc ? 1 : -1;

    //   return 0;
    // },

    getInternalId(source) {
      if (this.afmConfig && this.afmConfig.deepZoomSources) {
        if (source == null) return null;

        let found = this.afmConfig.deepZoomSources.indexOf(source);

        if (found < 0) return null;

        if (found < 9) return "0" + (found + 1);

        return (found + 1).toString();
      }

      return null;
    },

    /**
     * Get AFM config
     */
    getAfmConfig() {
      //this.isLoading = true;
      this.$store
        .dispatch(Action.AFM_CONFIG_GET)
        .then(() => {
          //this.afmConfig = payload;
          //this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          //this.isLoading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.end {
  float: right;
}
</style>
